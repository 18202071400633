<template>
  <transition
    :name="direction === 'bottom' ? 'slide-top' : direction === 'top' ? 'slide-bottom' : null "
  >
    <div
      class="mw-100 fixed cl-accent bg-cl-primary"
      :class="direction === 'top' ? 'top-sidebar' : direction === 'bottom' ? 'bottom-sidebar' : null "
      data-testid="sidebar"
      v-if="isOpen"
    >
      <component :is="component" @close="$emit('close')" @reload="getComponent" />
    </div>
  </transition>
</template>

<script>
import LoadingSpinner from 'theme/components/theme/blocks/AsyncSidebar/LoadingSpinner.vue';
import LoadingError from 'theme/components/theme/blocks/AsyncSidebar/LoadingError.vue';

export default {
  props: {
    asyncComponent: {
      type: Function,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    /** "bottom" or "top"  */
    direction: {
      type: String,
      default: 'bottom'
    }
  },
  name: 'AsyncBottomSideBar',
  data () {
    return {
      component: null
    };
  },
  created () {
    this.getComponent();
  },
  methods: {
    getComponent () {
      this.component = () => ({
        component: this.asyncComponent(),
        loading: LoadingSpinner,
        error: LoadingError,
        timeout: 3000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";

.slide-top-enter-active,
.slide-top-leave-active,
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: transform 0.25s;
}

.slide-top-enter,
.slide-top-leave-to {
  transform: translateY(100%);
}

.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translateY(-100%);
}

.bottom-sidebar {
  bottom: 0;
  z-index: 999;
  // height: 40%;
  width: 800px;
  min-width: 320px;
  overflow: visible;
}

.top-sidebar {
  height: 40vh;
  width: 350px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 3;

  @media (max-width: 767px) {
    width: 100vh;
  }
}

.close {
  i {
    opacity: 0.6;
  }
  &:hover,
  &:focus {
    i {
      opacity: 1;
    }
  }
}
</style>
